import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { FF_MATERIAL_CONTROLS_CONFIG_TOKEN, FFMaterialControlsConfig, FFMaterialControlsConfigService } from '@ff/material-controls';
import { EFileType, LocalizationModule, LocalizationModuleConfig } from '@ff/localization';
import * as defaultLocalizationJson from './assets/localizations/_default.localization.json';
import { APP_INITIALIZER, importProvidersFrom, isDevMode } from '@angular/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY, MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY } from '@angular/material/datepicker';
import { Overlay } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { routes } from './app/app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { ThemeService } from './app/shared/services/theme.service';
import { DOCUMENT, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { devTools } from '@ngneat/elf-devtools';
import { PersistenceRepository } from './app/shared/store/persist.repository';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BaseUrlInterceptor } from './app/shared/interceptors/base-url.interceptor';
import { SignalRService } from './app/shared/services/signal-r.service';
import { PrivatePermissionService } from './app/shared/services/private-permission.service';
import { JwtTokenInterceptor } from './app/shared/interceptors/jwt-token.interceptor';
import { LogoutIfUnauthorizedInterceptor } from './app/shared/interceptors/logout-if-unauthorized.interceptor';
import { RefreshAuthorizationService } from './app/shared/services/refresh-authorization.service';
import { LoginPermissionService } from './app/shared/services/login-permission.service';

if (isDevMode()) {
  devTools();
}

const FF_CONTROL_DATE_FORMAT = environment.appSettings.controls.dateFormat;

const defaultErrorMappings = {
  required: '%[global.error-messages.field-required]%',
  pattern: '%[global.error-messages.field-pattern]%',
  minlength: '%[global.error-messages.field-minlength]%',
  maxlength: '%[global.error-messages.field-maxlength]%',
  requiredTrue: '%[global.error-messages.field-required-true]%',
  email: '%[global.error-messages.field-email]%',
  max: '%[global.error-messages.field-max]%',
  min: '%[global.error-messages.field-min]%',
  noPasswordMatch: '%[global.error-messages.field-passwords-do-not-match]%',
  dateNotLaterThan: '%[global.error-messages.field-date-not-later-than]%',
  dateNotEarlierThan: '%[global.error-messages.field-date-not-earlier-than]%',

  // material controls errors
  matDatepickerMin: '%[global.error-messages.material.datepicker-min]%',
  matDatepickerMax: '%[global.error-messages.material.datepicker-max]%',
  matDatepickerFilter: '%[global.error-messages.material.datepicker-filter]%',
  matDatepickerParse: '%[global.error-messages.material.datepicker-parse]%',
  passwordWeak: '%[global.error-messages.weak-password]%'
};

const materialControlsConfig: FFMaterialControlsConfig = {
  floatLabel: environment.appSettings.controls.floatLabel,
  appearance: environment.appSettings.controls.appearance,
  tooltipDuration: environment.appSettings.tooltip.hideDuration,
  tooltipThrottleTime: environment.appSettings.tooltip.showDuration,
  autocompleteDebounceTime: environment.appSettings.controls.inputDebounceTime,
  filtersConfig: {
    numeric: '%[text-input.filters.numeric]%',
    alphaSpace: '%[text-input.filters.alpha-space]%',
    alphaNumeric: '%[text-input.filters.alpha-numeric]%',
    alphaNumericNoSpace: '%[text-input.filters.alpha-numeric-no-space]%',
    alphaNumericSpace: '%[text-input.filters.alpha-numeric-space]%',
    alphaNumericSymbols: '%[text-input.filters.alpha-numeric-symbols]%',
    alphaNumericDashSpace: '%[text-input.filters.latin-number-dash-space]%',
    alphaNumericDashSpaceCommaSlash: '%[text-input.filters.latin-number-dash-space-comma-slash]%',
    alphaCyrillicNumeric: '%[text-input.filters.latin-cyrillic-numbers]%',
    email: '%[text-input.filters.email]%',
    cityStreetBuilding: '%[text-input.filters.building]%',
    names: '%[text-input.filters.names]%',
  },
};

const localizationConfig: LocalizationModuleConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultLocalizations: (defaultLocalizationJson as any)?.default,
  defaultLocalizationKeys: defaultErrorMappings,
  localizationFilesUrl: '/assets/localizations',
  replaceValueWithDefaultLocalizationIfEmptyString: true,
  fileType: EFileType.json,
};

export function initApp(themeService: ThemeService): () => void {
  return () => themeService.setTheme(environment.vendor.theme.name);
}

bootstrapApplication(AppComponent, {
  providers: [
    // Import from modules
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      MatLuxonDateModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      HttpClientModule,
      LocalizationModule.forRoot(localizationConfig),
      RouterModule.forRoot(routes),
      MatBottomSheetModule,
    ),
    provideAnimations(),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // Providers for date adapter
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: FF_CONTROL_DATE_FORMAT },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATEPICKER_SCROLL_STRATEGY, deps: [Overlay], useFactory: MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY },
    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogoutIfUnauthorizedInterceptor, multi: true },
    { provide: FF_MATERIAL_CONTROLS_CONFIG_TOKEN, deps: [FFMaterialControlsConfigService], useValue: materialControlsConfig },
    // Init application
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ThemeService],
      multi: true,
    },
    ThemeService,
    // ElectronService,
    { provide: DOCUMENT, useValue: document },
    PersistenceRepository,
    SignalRService,
    PrivatePermissionService,
    LoginPermissionService,
    RefreshAuthorizationService
  ],
}).catch((err) => console.error(err));
