import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PersistenceRepository } from '../store/persist.repository';

@Injectable()
export class LogoutIfUnauthorizedInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _persistRepo: PersistenceRepository
  ) {}

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status === 401 || error.status === 403) {
          this._persistRepo.setAuthenticationTokens(null);
          return from(this._router.navigate(['public', 'auth', 'sing-in'])).pipe(concatMap(() => throwError(() => error)));
        }

        return throwError(() => error);
      })
    );
  }
}
