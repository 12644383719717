import { Injectable } from '@angular/core';
import { PersistenceRepository } from '../store/persist.repository';
import { Router } from '@angular/router';
import { concatMap, from, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { IReceiveAccessToken } from '../store/models/receive-access-token';

@Injectable()
export class LoginPermissionService {

  constructor(
    private _persistRepo: PersistenceRepository,
    private _router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this._persistRepo.accessToken$$.pipe(
      take(1),
      concatMap((accessToken: IReceiveAccessToken | null) => {
        if (accessToken == null || accessToken.token.length === 0) {
          return of(true);
        }
        console.warn('login guard fired');
        return from(this._router.navigate(['private', 'dashboard']));
      }),
    );
  }
}
