<div class='row'>
  <div class='col-11'>
    <div class='d-flex justify-content-center'>
      <mat-button-toggle-group [formControl]='themeSwitcher'>
        <mat-button-toggle value='light'>Light</mat-button-toggle>
        <mat-button-toggle value='dark'>Dark</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class='col-1'>
    <button mat-icon-button class='ff-icon ff-close' (click)='onCloseClick()'></button>
  </div>
</div>
