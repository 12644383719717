import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { urlJoin } from 'url-join-ts';

@Injectable({ providedIn: 'root' })
export class BaseUrlInterceptor implements HttpInterceptor {

  private _headers: HttpHeaders = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json; charset=utf-8');

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    const apiReq = req.clone({
      url: urlJoin(environment.apiSettings.baseUrl, req.url),
      headers: this._headers
    });
    return next.handle(apiReq);
  }
}
