import { Inject, Injectable } from '@angular/core';
import { IpcRenderer, IpcRendererEvent } from 'electron';
import { WINDOW } from '@ff/contracts';

@Injectable({ providedIn: "root" })
export class ElectronService {
  private _ipc: IpcRenderer | undefined = void 0;

  constructor(
    @Inject(WINDOW) private _window: Window
  ) {
    if ((this._window as any).require != null) {
      try {
        this._ipc = (this._window as any).require('electron').ipcRenderer;
      } catch (error) {
        throw error;
      }
    } else {
      console.warn('Electron IPC was not loaded');
    }
  }

  get isElectronApp(): boolean {
    return this._window.navigator.userAgent.match(/Electron/) != null;
  }

  on(channel: string, listener: (event: IpcRendererEvent, ...args: any[]) => void): void {
    if (this._ipc == null) {
      return;
    }

    this._ipc.on(channel, listener);
  }

  send(channel: string, ...args: any[]): void {
    if (this._ipc == null) {
      return;
    }
    this._ipc.send(channel, ...args);
  }

  invoke(channel: string, ...args: any[]): Promise<any> {
    if (this._ipc == null) {
      return new Promise<any>((resolve, reject) => {});
    }
    return this._ipc.invoke(channel, ...args);
  }
}
