import { Inject, RendererFactory2 } from '@angular/core';
import { Injectable } from '@angular/core';
import { FFThemingServicesConfig, ThemeServiceBase } from '@ff/theming-services';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { concatMap, merge, of, switchMap, tap } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomThemeSwitcherComponent } from '../components/bottom-theme-switcher/bottom-theme-switcher.component';
import { take } from 'rxjs/operators';
import { ElectronService } from './electron.service';
import { FFThemeEvent, FFThemeMode, LOCAL_STORAGE, WINDOW } from '@ff/contracts';

export const THEME_DEV_URI_HOST = 'http://localhost:4200';

@Injectable()
export class ThemeService extends ThemeServiceBase {
  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) _document: Document,
    @Inject(WINDOW) window: Window,
    @Inject(LOCAL_STORAGE) localStorage: Storage,
    private _bottomSheet: MatBottomSheet,
    private _electronService: ElectronService,
  ) {
    const config: FFThemingServicesConfig = {
      isDevMode: environment.vendor.theme.devMode,
      vendorName: environment.vendor.company.name,
      themeDevUrlHost: THEME_DEV_URI_HOST,
      isUseCustomLogo: false,
      isProduction: !environment.vendor.theme.devMode,
      isAutoDarkMode: environment.vendor.theme.autoDarkMode,
      uiModules: ['base', 'extensions', 'root', 'material'],
      projectType: 'frontoffice'
    };
    super(rendererFactory, config, _document, window, localStorage);

    if (environment.vendor.theme.autoDarkMode) {
      if (this._electronService.isElectronApp) {
        this._initDarkModeElectronApp();
      } else {
        this._initDarkModeWebApp();
      }
    }
  }

  private _initDarkModeWebApp(): void {
    let themeEvent: FFThemeEvent;
    this.initDarkModeAutoSwitch().pipe(
      concatMap((event: FFThemeEvent | undefined) => {
        if (event != null) {
          themeEvent = event;
          const bottomSheet = this._bottomSheet.open(BottomThemeSwitcherComponent, {
            data: { themeEvent },
          });
          return merge(bottomSheet.afterDismissed(), bottomSheet.backdropClick());
        }
        return of(void 0);
      }),
      take(1),
      tap((switcherResult: FFThemeMode | MouseEvent | undefined) => {
        if (switcherResult != null && !(switcherResult instanceof MouseEvent)) {
          this.setPreferredThemeMode(switcherResult);
        } else if (themeEvent != null) {
          this.setThemeMode(themeEvent === 'change-light' ? 'light' : 'dark');
        }
      }),
      switchMap(() => this.handleSystemThemeChanged$())
    ).subscribe();
  }

  private _initDarkModeElectronApp(): void {
    let themeEvent: FFThemeEvent;
    this.initDarkModeAutoSwitch().pipe(
      concatMap((event: FFThemeEvent | undefined) => {
        if (event != null) {
          themeEvent = event;
          const bottomSheet = this._bottomSheet.open(BottomThemeSwitcherComponent, {
            data: { themeEvent },
          });
          return merge(bottomSheet.afterDismissed(), bottomSheet.backdropClick());
        }
        return of(void 0);
      }),
      take(1),
      tap((switcherResult: FFThemeMode | MouseEvent | undefined) => {
        if (switcherResult != null && !(switcherResult instanceof MouseEvent)) {
          this.setPreferredThemeMode(switcherResult);
          this._electronService.send(`theme:preferred:${themeEvent}`);
        } else if (themeEvent != null) {
          const themeMode: FFThemeMode = themeEvent === 'change-light' ? 'light' : 'dark';
          this.setThemeMode(themeMode);
        }
      }),
      switchMap(() => this.handleSystemThemeChanged$())
    ).subscribe();
  }
}
