import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersistenceRepository } from '../store/persist.repository';
import { concatMap, EMPTY, Observable, switchMap, tap, timer, withLatestFrom } from 'rxjs';
import { filter, mapTo, take } from 'rxjs/operators';
import { IReceiveAccessToken } from '../store/models/receive-access-token';

@Injectable()
export class RefreshAuthorizationService {

  constructor(
    private _http: HttpClient,
    private _persistRepo: PersistenceRepository
  ) { }

  refreshAuthorizationWithTimer$(): Observable<void> {
    return this._persistRepo.accessToken$$.pipe(
      filter(accessToken => accessToken != null),
      take(1),
      switchMap((accessToken: IReceiveAccessToken | null) => {
        return accessToken != null ? timer(accessToken.expiresIn * 1000) : EMPTY;
      }),
      withLatestFrom(this._persistRepo.accessToken$$),
      concatMap(([_, accessToken]) => this._http.post<IReceiveAccessToken>('token/refresh', { accessToken: accessToken?.token })),
      tap(accessToken => this._persistRepo.setAccessToken(accessToken)),
      mapTo(void 0)
    );
  }
}
