import { Injectable } from '@angular/core';
import { PersistenceRepository } from '../store/persist.repository';
import { concatMap, from, Observable, of } from 'rxjs';
import { mapTo, take } from 'rxjs/operators';
import { IReceiveAccessToken } from '../store/models/receive-access-token';
import { Router } from '@angular/router';

@Injectable()
export class PrivatePermissionService {

  constructor(
    private _persistRepo: PersistenceRepository,
    private _router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this._persistRepo.accessToken$$.pipe(
      take(1),
      concatMap((accessToken: IReceiveAccessToken | null) => {
        if (accessToken == null) {
          return from(this._router.navigate(['public', 'auth', 'sign-in'], { replaceUrl: true })).pipe(mapTo(false));
        }
        return of(true);
      }),
    );
  }
}
