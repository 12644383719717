import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FFThemeEvent, FFThemeMode } from '@ff/contracts';

@Component({
  standalone: true,
  selector: 'app-bottom-theme-switcher',
  templateUrl: './bottom-theme-switcher.component.html',
  styleUrls: ['./bottom-theme-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatRadioModule, ReactiveFormsModule, MatButtonToggleModule],
})
export class BottomThemeSwitcherComponent {
  themeSwitcher: FormControl<FFThemeMode>;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { themeEvent: FFThemeEvent },
    private _bottomSheet: MatBottomSheet,
  ) {
    let checkedValue: FFThemeMode;
    if (data.themeEvent === 'change-light') {
      checkedValue = 'light';
    } else {
      checkedValue = 'dark';
    }
    this.themeSwitcher = new FormControl<FFThemeMode>(checkedValue, { nonNullable: true });

    this._subscribeOnInit();
  }

  onCloseClick(): void {
    this._bottomSheet.dismiss();
  }

  private _subscribeOnInit(): void {
    this.themeSwitcher.valueChanges
      .pipe(
        tap((themeValue) => {
          this._bottomSheet.dismiss<FFThemeMode>(themeValue);
        }),
      )
      .subscribe();
  }
}
