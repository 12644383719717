import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, Observable } from 'rxjs';
import { PersistenceRepository } from '../store/persist.repository';
import { take } from 'rxjs/operators';
import { IReceiveAccessToken } from '../store/models/receive-access-token';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(private _persistRepo: PersistenceRepository) {}

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return this._persistRepo.accessToken$$.pipe(
      take(1),
      concatMap((accessToken: IReceiveAccessToken | null) => {
        if (accessToken != null && accessToken.token.trim() !== '') {
          req = req.clone({
            setHeaders: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${accessToken.token}`,
            },
          });
        }

        return next.handle(req);
      })
    );
  }
}
