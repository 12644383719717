import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PersistenceRepository } from './shared/store/persist.repository';
import { ThemeService } from './shared/services/theme.service';
import { SignalRService } from './shared/services/signal-r.service';
import { filter, switchMap } from 'rxjs/operators';
import { RefreshAuthorizationService } from './shared/services/refresh-authorization.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {
  constructor(
    private _persistRepo: PersistenceRepository,
    private _themeService: ThemeService,
    private _signalRService: SignalRService,
    private _refreshAuthService: RefreshAuthorizationService
  ) {
    this._persistRepo.accessToken$$.pipe(
      filter(accessToken => accessToken != null),
      switchMap(() => this._refreshAuthService.refreshAuthorizationWithTimer$()),
      takeUntilDestroyed()
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._persistRepo.destroyStore();
    this._themeService.destroy();
    this._signalRService.destroy();
  }
}
