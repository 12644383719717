import { createStore, select, Store, StoreDef, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { Observable, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ISettings } from './models/settings';
import { IReceiveAuthenticationTokens } from './models/receive-authentication-tokens';
import { isNullOrEmpty } from '@ff/utils';
import { IReceiveAccessToken } from './models/receive-access-token';
import { IVersions } from './models/versions';

interface IPersistState {
  qrCodeString: string | null;
  settings: ISettings | null;
  authenticationTokens: IReceiveAuthenticationTokens | null;
  versions: IVersions | null;
  isBalanceVisible: boolean ;
}

@Injectable({ providedIn: 'root' })
export class PersistenceRepository {
  private _persistenceStore: Store<StoreDef<IPersistState>, IPersistState> =
    createStore({ name: 'persistence-store' }, withProps<IPersistState>({
      qrCodeString: null,
      settings: null,
      authenticationTokens: null,
      versions: null,
      isBalanceVisible:true
    }));

  isInitialStateLoading$$: Observable<boolean> = this._persistenceStore.pipe(select((state: IPersistState) => isNullOrEmpty(state.qrCodeString)));
  qrCodeString$$: Observable<string | null> = this._persistenceStore.pipe(select((state: IPersistState) => state.qrCodeString));
  loginToken$$: Observable<string | null> = this._persistenceStore.pipe(select((state: IPersistState) => state.authenticationTokens?.loginToken ?? null));
  accessToken$$: Observable<IReceiveAccessToken | null> = this._persistenceStore.pipe(select((state: IPersistState) => state.authenticationTokens?.accessToken ?? null));
  versions$$: Observable<IVersions | null> = this._persistenceStore.pipe(select((state: IPersistState) => state.versions));
  isBalanceVisible$$: Observable<boolean> = this._persistenceStore.pipe(select((state: IPersistState) => state.isBalanceVisible));

  constructor() {
    persistState(this._persistenceStore, { key: 'persistence-store', storage: localStorageStrategy });
  }

  setQrCodeString(qrCodeString: string | null): void {
    this._persistenceStore.update((state: IPersistState) => ({ ...state, qrCodeString }));
  }

  setBalanceVisible(isBalanceVisible: boolean ): void {
    this._persistenceStore.update((state: IPersistState) => ({ ...state, isBalanceVisible }));
  }

  setAuthenticationTokens(authenticationTokens: IReceiveAuthenticationTokens | null): void {
    this._persistenceStore.update((state: IPersistState) => ({ ...state, authenticationTokens }));
  }

  setAccessToken(accessToken: IReceiveAccessToken): void {
    this._persistenceStore.update((state: IPersistState) => ({ ...state, authenticationTokens: state.authenticationTokens != null
        ? { ...state.authenticationTokens, accessToken }
        : null }));
  }

  setSettings$(settings: Partial<ISettings>): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this._persistenceStore.update((state: IPersistState) => ({ ...state, settings: state.settings != null
            ? { ...state?.settings, ...settings }
            : {
              language: settings.language ?? null,
              pinCount: settings.pinCount ?? null,
              usePin: settings.usePin ?? null
            } }));
      })
    );
  }

  setVersions(versions: Partial<IVersions>): void {
    this._persistenceStore.update((state: IPersistState) => ({ ...state, versions: state.versions != null
      ? { ...state?.versions, ...versions }
      : {
        backendVersion: versions.backendVersion ?? null,
        frontendVersion: versions.frontendVersion ?? null,
        materialUiVersion: versions.materialUiVersion ?? null
      } })
    );
  }

  destroyStore(): void {
    this._persistenceStore.destroy();
  }

  reset(): void {
    this._persistenceStore.reset();
  }
}
