import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { PersistenceRepository } from '../store/persist.repository';
import { IReceiveAuthenticationTokens } from '../store/models/receive-authentication-tokens';
import { IReceiveAccessToken } from '../store/models/receive-access-token';
import { IReceiveAuthRequestId } from '../store/models/receive-auth-request-id';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { catchError, concatMap, from, interval, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { filter, mapTo, take } from 'rxjs/operators';

@Injectable()
export class SignalRService {
  private _reconnectionTimeMs: number = 15;
  private _reconnectInvoke$$: Subject<void> = new Subject<void>();

  private readonly _hubConnection: HubConnection;

  constructor(
    private _persistRepo: PersistenceRepository,
    private _router: Router
  ) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.apiSettings.baseWebSocketUrl}auth/proccess`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this._startConnection().pipe(
      concatMap(() => this._checkConnectionState())
    ).subscribe();

    this._hubConnection.onreconnected(() => {
      from(this._router.navigate(['public', 'auth', 'sign-in'])).subscribe();
    });

    this._hubConnection.on('ReceiveAuthRequestId', (data) => {
      const parsedData = JSON.parse(data);
      if (this._isReceiveAuthRequestId(parsedData)) {
        this._persistRepo.setQrCodeString((parsedData as IReceiveAuthRequestId).loginUrl);
      }
    });

    this._hubConnection.on('ReceiveAuthenticationTokens', (data) => {
      const parsedData: unknown = JSON.parse(data);
      if (this._isReceiveAuthenticationTokens(parsedData)) {
        this._persistRepo.setAuthenticationTokens(parsedData as IReceiveAuthenticationTokens);
      }
    });
  }

  destroy(): void {
    from(this._hubConnection.stop()).subscribe();
  }

  reconnect(): void {
    this._reconnectInvoke$$.next();
    from(this._hubConnection.stop()).pipe(
      switchMap(() => this._startConnection()),
      concatMap(() => this._checkConnectionState())
    ).subscribe();
  }

  private _startConnection(): Observable<void> {
    return from(this._hubConnection.start()).pipe(
      switchMap(() => from(this._router.navigate(['public', 'auth', 'sign-in']))),
      catchError(_ => {
        this._persistRepo.setAuthenticationTokens(null);
        return from(this._router.navigate(['public', 'service-unavailable'])).pipe(
          concatMap(() => this._checkConnectionState())
        );
      }),
      mapTo(void 0)
    );
  }

  private _isReceiveAuthenticationTokens(obj: unknown): obj is IReceiveAuthenticationTokens {
    if (obj != null && typeof obj === 'object') {
      const authenticationTokensParams: (keyof IReceiveAuthenticationTokens)[] = ['loginToken', 'accessToken'];
      const accessTokensParams: (keyof IReceiveAccessToken)[] = ['token', 'expiresIn'];
      return authenticationTokensParams.every(param => Object.prototype.hasOwnProperty.call(obj, param))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line dot-notation
        && accessTokensParams.every(param => Object.prototype.hasOwnProperty.call(obj['accessToken'], param));
    }
    return false;
  }

  private _isReceiveAuthRequestId(obj: unknown): obj is IReceiveAuthRequestId {
    if (obj != null && typeof obj === 'object') {
      const param: keyof IReceiveAuthRequestId = 'loginUrl';
      return Object.prototype.hasOwnProperty.call(obj, param);
    }
    return false;
  }

  private _checkConnectionState(): Observable<void> {
    return interval(this._reconnectionTimeMs * 1000).pipe(
      // eslint-disable-next-line dot-notation
      filter(() => this._hubConnection['_connectionState'] === HubConnectionState.Disconnected),
      switchMap(() => this._startConnection()),
      take(4),
      takeUntil(this._reconnectInvoke$$)
    );
  }
}
